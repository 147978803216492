import '../App.css';
import NavBar from './NavBar';

import Footer from './Footer';

function App() {
  return (
    <>
      <NavBar />
      <Footer />
    </>
  );
}

export default App;
