import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import logo from '../logo.svg';

function NavBar() {
    return (
        <Navbar sticky="top" bg="dark" variant="dark">
            <Container>
                <Navbar.Brand id="navbar_text">
                    <img alt="" src={logo} width="30" height="30" className="d-inline-block align-top"/>
                    {' '}
                    Arthur V Fain
                </Navbar.Brand>
                
            </Container>
        </Navbar>
    )
}

export default NavBar;